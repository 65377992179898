import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {createRoot} from 'react-dom/client';
import './index.css';
import '@styles/styles.scss';
import '@styles/tailwind.css';
import './lang/i18n';
import {StoreProvider} from 'easy-peasy';
import React from "react";
import * as Sentry from "@sentry/react";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from '@store';

// Check for array method Matches support - IE alternative
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

const environment = process.env.REACT_APP_ENVIRONMENT;
if (environment === 'production') {
    // EncryptionSchemePolyfills.install()EncryptionSchemePolyfills.install()EncryptionSchemePolyfills.install()EncryptionSchemePolyfills.install()
    Sentry.init({
        dsn: "https://68e0e3eaa977473bc49449186a1fa201@o4508930925985792.ingest.de.sentry.io/4508931204251728",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        environment: 'production',
        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
// eslint-disable-next-line import/no-named-as-default-member
root.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <StoreProvider store={store}>
        <App />
      </StoreProvider>
    </Sentry.ErrorBoundary>
);

// If you want your app to dbg work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
//   onUpdate: (reg) => {
//     const registrationWaiting = reg.waiting;
//     if (registrationWaiting) {
//       registrationWaiting.postMessage({ type: "SKIP_WAITING" });
//       registrationWaiting.addEventListener("statechange", (e) => {
//         if (e.target.state === "activated") {
//           window.location.reload();
//         }
//       });
//     }
//   },
// });
serviceWorker.unregister();
