import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { removeWishlist } from '@http/endpoints/wishlist';

const useRemoveWishlistMutation = (id: number, type: 'movies'|'scenes') => {
  const { mutate: removeWishlistMutation, isLoading: isRemoveWishlistMutationLoading, mutateAsync: removeWishlistMutationAsync } = useMutation(
    () => removeWishlist(id, type)
  );
  return { removeWishlistMutation, isRemoveWishlistMutationLoading, removeWishlistMutationAsync };
};

export default useRemoveWishlistMutation;
