import { useMutation } from '@tanstack/react-query';
import { addWishlist } from '@http/endpoints/wishlist';

const useAddWishlistMutation = (id: number, type: 'movies' | 'scenes') => {
  const {
    mutate: addWishlistMutation,
    isLoading: isAddWishlistMutationLoading,
    mutateAsync: addWishlistMutationAsync,
  } = useMutation(() => addWishlist(id, type));
  return { addWishlistMutation, isAddWishlistMutationLoading, addWishlistMutationAsync };
};

export default useAddWishlistMutation;
