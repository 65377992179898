import http from '@http';

export const getLanguages = async () => {
  const { data } = await http.get('/languages');
  return data;
};


export const storeUserLang = async () => {
  const {data} = await http.post('/per-language');
  return data;
};