import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-landscape-fullscreen';

/**
 * @param  src
 * @param  showVideo
 * @param  isMobile
 * @param  thumb
 */
// eslint-disable-next-line import/prefer-default-export
const VideoThumbnail = ({ src, showVideo, thumb, onVideoClick = () => {} }) => {

  const videoRef = useRef(null);
  const playerRef  = useRef(null);


  const videoJsOptions = {
    preload: 'none',
    techOrder: ["html5"],
    fluid: true,
    autoplay: true,
    responsive: true,
    controlBar: {
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true,
        },
      },
    },
    sources: [{
      src: src
    }],
    poster: thumb,
    html5: {
      vhs: {
        smoothQualityChange: true,
        overrideNative: true,
        bufferLowWaterLine: 30,
        bufferHighWaterLine: 600,
      },
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;


    player.on("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (onVideoClick) onVideoClick();
    });

    // You can handle player events here, for example:
    player.on('waiting', () => {
     // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
    //  videojs.log('player will dispose');
    });
  };

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const player = playerRef.current = videojs(videoRef.current, videoJsOptions, () => {
        //videojs.log('player is ready');
        handlePlayerReady && handlePlayerReady(player);
      });
      player.landscapeFullscreen({
        fullscreen: {
          exitOnRotate: true,
          alwaysInLandscapeMode: false,
          iOS: true,
        },
      });


    } else {
      const player = playerRef.current;

      player.autoplay(videoJsOptions.autoplay);
      player.src(videoJsOptions.sources);
    }
  }, [videoJsOptions, videoRef]);

  useEffect(() => {
    if (showVideo && videoRef?.current) {
      const p = videoRef.current.play();
      if (p !== undefined) p.catch(function() {
      });
    } else if (!showVideo && videoRef?.current) videoRef.current.pause();
  }, [showVideo, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);



  return (
    <div data-vjs-player>
      <video ref={videoRef} preload={'none'} muted loop  style={{ pointerEvents: "none" }} className={` ${showVideo ? 'block' : 'hidden'} w-full h-full video-js vjs-default-skin`} />
    </div>
  );
};

export default VideoThumbnail;
