import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { isUserInfoModel, useStoreState } from '@store';
import { updateStreamTime } from '@http/endpoints/movies';

interface Props {
  url: string;
  type: string;
  mediaID: string | number;
}

const StreamPlayer: FC<Props> = ({ url, type, mediaID }) => {
  const { userInfo } = useStoreState((state) => state.user);
  const countRef = useRef(0);
  const [start, setStart] = useState(false);
  const interval = useRef<null|NodeJS.Timer>(null);
  const SECONDS_INTERVAL = 10;
  
  const updateTime = useCallback(async (time ?: number) => {
    await updateStreamTime({
      type,
      user_id: isUserInfoModel(userInfo) ? userInfo.id : -1,
      resource_id: mediaID,
      time
    });
  },[mediaID, type, userInfo]);

  useEffect(() => {
    if(start) {
      if(countRef.current === 0) {
        updateTime(SECONDS_INTERVAL);
        countRef.current = 1;
      }
      interval.current = setInterval(() => updateTime(SECONDS_INTERVAL), SECONDS_INTERVAL*1000);
    }
  }, [start]);


  const handlePlayStart = () => {
    setStart(true);
  };

  const handlePlay = useCallback(() => {
    setStart(true);
  },[setStart]);

  const handlePause = useCallback(() => {
    if(interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
    setStart(false);
  },[setStart]);

  const handleEnd = () => {
    setStart(false);
  };

  useEffect(()=> {
    return () => {
      if(interval.current) {
        countRef.current = 0;
        clearInterval(interval.current);
        interval.current = null;
      }
    };
  },[]);

  return (
    <div className="trailer-container bg-black">
      <ReactPlayer
        playing
        onStart={handlePlayStart}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnd}
        url={url}
        controls
        height="100%"
        width="100%"
        pip
        config={{
          file: {
            hlsOptions: {
              maxBufferLength: 600,
              maxMaxBufferLength: 600,
              liveSyncDuration: 30,
              liveMaxLatencyDuration: 60
            },
          },
        }}
      />
    </div>
  );
};

export default StreamPlayer;
