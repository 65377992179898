import React, { FC, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { PhoneVerify } from '../common/modals/phone-verify';
import { EmailSubscribeModal } from '../common/modals/subscribe';
import BaseModal from '../common/modals/modal/BaseModal';
import writeCookie from '../../helpers/writeCookie';
import readCookie from '../../helpers/readCookie';
import { sendTrackingCode } from '../../services/http/endpoints/tracking';
import UnauthenticatedRoutes from './unauthenticated';
import AuthenticatedRoutes from './authenticated';
import { useSendGift } from '@hooks';
import { DepositModal } from '@common/modals/deposit';
import { useStoreActions, useStoreState } from '@store';
import { Footer, Header } from '@layout';
import 'react-loading-skeleton/dist/skeleton.css';
import useErrorPayments from '../../hooks/useErrorPayments';
import RegisterPromo from '@common/modals/registerPromo';
import RegisterModal from '@common/modals/register/RegisterModal';
import { getSettings } from '@http/endpoints/settings';
import AdultWarning from '@common/modals/adult-warning';
import { storeUserLang } from '@http/endpoints/languages';

const Pages: FC = () => {
  const { showDepositModal, showPhoneVerify, showMessageModal } = useStoreState((state) => state);
  const { isAuthenticated, settings } = useStoreState((state) => state.user);
  const { showAgreement } = useStoreState((state) => state.agreement);
  const { setGiftCode, setSettings } = useStoreActions((state) => state.user);
  const { setShowDeposit, setShowPhoneVerify, setShowLogin, setShowMessageModal } = useStoreActions(
    (actions) => actions,
  );

  useErrorPayments();

  const location = useLocation();
  const { t } = useTranslation();
  const { claimGift } = useSendGift();
  const {data, isLoading, isSuccess, isFetched} = useQuery(['settings'], getSettings);

  useEffect(() => {
    const parsedParams = parse(location.search);
    async function sendTrack() {
      const clicker_id = readCookie('clicker_id') || '';
      const tracking_code = parsedParams.tc;
      const { data } = await sendTrackingCode({ clicker_id, tracking_code });
      if (data?.data?.data?.clicker_id) writeCookie('clicker_id', data.data.data.clicker_id);
      if (parsedParams?.promo_code) {
        Cookies.set('bum_promo_code', parsedParams.promo_code);
      }
    }

    if (parsedParams?.tc) sendTrack();
  }, [location]);

  useEffect(() => {
    const parsedParams = parse(location.search);
    if (parsedParams?.gift_code) {
      if (!isAuthenticated) {
        setGiftCode(parsedParams?.gift_code);
        setShowLogin(true);
      } else claimGift(parsedParams?.gift_code);
    }
  }, [location, setGiftCode, isAuthenticated, setShowLogin, claimGift]);

  useEffect(()=> {
    if(!data || settings || isLoading || !isFetched || !isSuccess) return;
    setSettings(data?.data || {});
  },[data, isLoading, isFetched, isSuccess, settings]);


  useEffect(() => {
    storeUserLang().then(v=> {
      console.log(v);
    }).catch(v => console.error(v));
  }, []);

  return (
    <>
      <Header />
      <div className="min-h-screen main-screen">
        <section
          id="main-section"
          className={`${
            showAgreement && !isAuthenticated
              ? 'pt-16 -mt-4 lg:mt-5 lg:pt-20'
              : 'px-3 md:px-8 lg:px-12 sm:px-6 pt-[4rem] mt-5 lg:pt-15 lg:mt-10 pb-20'
          }`}
        >
          <Switch>
            <Route path="/auth" component={AuthenticatedRoutes} />
            <Route path="/" component={UnauthenticatedRoutes} />
          </Switch>

          {showDepositModal ? <DepositModal show={showDepositModal} hide={() => setShowDeposit(false)} /> : null}
          <PhoneVerify show={!!Object.keys(showPhoneVerify).length} hide={() => setShowPhoneVerify({})} />
          <BaseModal show={showMessageModal} hide={() => setShowMessageModal(false)}>
            <div className="pt-10 message-modal">
              <p className="">
                {t('Happy that you are back again! As a little welcome back gift we uploaded')} <b>10€</b>{' '}
                {t('for free to your account which you can use on every content you want. (without Club)')}
              </p>
              <p className="mt-5 ">{t('Your Beate Uhse Team')}</p>
              <div className="text-right">
                <button
                  onClick={() => setShowMessageModal(false)}
                  className="base-buttons py-1 px-1 focus:outline-none font-semibold mt-5 w-24 transition duration-150 ease-in-out bg-mainRed outline-none hover:opacity-75 text-white rounded-md"
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </BaseModal>
          <EmailSubscribeModal />
          <RegisterPromo />
          <RegisterModal />
          <AdultWarning />
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Pages;
