import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { queryCache } from 'react-query';
import {
  useAddWishlistMutation,
  useRemoveWishlistMutation,
} from '../../../../pages/unauthenticated/movie/components/MovieInfo/mutation';
import { queryClient } from '../../../../../core/root/ReactQueryProvider';
import styles from './FavoriteButton.module.scss';

type Props = {
  active?: boolean;
  id: number;
  type: 'movies' | 'scenes'
};

const FavoriteButton: FC<Props> = ({ active, id, type }) => {
  const { t } = useTranslation();


  const { addWishlistMutationAsync, isAddWishlistMutationLoading } = useAddWishlistMutation(id, type);
  const { removeWishlistMutationAsync, isRemoveWishlistMutationLoading } = useRemoveWishlistMutation(id ,type);
  const { addToast } = useToasts();
  const text = useMemo(() => t(active ? 'Remove from Wishlist' : 'Add to Wishlist'), [t, active]);
  const isLoading = useMemo(() => isRemoveWishlistMutationLoading || isAddWishlistMutationLoading, [isRemoveWishlistMutationLoading, isAddWishlistMutationLoading]);

  const queryKey = useMemo(() => (type === 'movies' ? ['movie-by-id', `${id}`] : ['scenes', { id: `${id}` }]), [type, id]);

  const handleClick = useCallback(async () => {
    if (isLoading) return;
    try {
      const data = await (active ? removeWishlistMutationAsync() : addWishlistMutationAsync());
      if(data?.status === 'success') {
        addToast(data.message, { appearance: 'success' });
        if(type === 'movies') await queryClient.invalidateQueries({ queryKey });
        else await queryCache.invalidateQueries(queryKey);
      }
    }catch (e: any) {
      addToast(e?.message, { appearance: 'error' });
    }
  }, [isLoading, active, removeWishlistMutationAsync, addWishlistMutationAsync, addToast, type, queryKey]);


  return (
    <div
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      className={`${
        !active ? styles.wishlist_button : `${styles.wishlist_button} ${styles.active_wishlist_button}`
      }${isLoading ? ' disabled' : ''} w-full flex content-center text-white items-center px-5 py-3 rounded-md cursor-pointer shadow-lg select-none`}
    >
      {
        !isLoading ? (
          <>
          <span>
        <i className="fas fa-star" />
      </span>
            <p className={styles.text}>{text}</p>
          </>
        ) :    <div className={`${styles.wishlist_button_spinner}`}></div>
      }
    </div>
  );
};

export default FavoriteButton;
